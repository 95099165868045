.app__certification {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__certification-item {
    width: 60%;
    min-height: 320px;
    background-color: var(--white-color);
    display: flex;
    flex-direction: row;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  
    transition: all 0.3s ease-in-out;
  
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
  
    @media screen and (min-width: 2000px) {
      min-height: 450px;
  
      img {
        width: 150px;
        height: 150px;
      }
    }
  
    @media screen and (max-width: 850px) {
      width: 100%;
    }
  
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  
  .app__certification-content {
    flex: 1;
    height: 100%;
    padding: 0 2rem;
    text-align: left;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  
    p {
      font-size: 1.25rem;
      line-height: 2rem;
      color: var(--black-color);
      font-family: var(--font-base);
  
      @media screen and (min-width: 2000px) {
        font-size: 2rem;
        line-height: 3.5rem;
      }
    }
  
    h4 {
      font-weight: 600;
      color: var(--secondary-color);
      margin-top: 2rem;
    }
  
    h5 {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }
  
    @media screen and (max-width: 600px) {
      margin-top: 2rem;
      padding: 0;
    }
  }
  
  .app__certification-btns {
    flex-direction: row;
    margin-top: 1rem;
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--white-color);
  
      margin: 1rem;
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 20px;
        height: 20px;
        color: var(--secondary-color);
      }
  
      &:hover {
        background-color: var(--secondary-color);
  
        svg {
          color: var(--white-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 100px;
        height: 100px;
  
        svg {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
  
  .app__certification-brands {
    width: 80%;
    flex-wrap: wrap;
    margin-top: 2rem;
  
    div {
      width: 150px;
      margin: 1.5rem;
  
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        filter: grayscale(1);
      }
  
      &:hover {
        img {
          filter: grayscale(0);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 210px;
        margin: 2rem;
      }
  
      @media screen and (max-width: 450px) {
        width: 120px;
        margin: 1rem;
      }
    }
  
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

  .window {
    @media screen and (min-width: 1000px) {
        width: 800px;
        height: 600px;
      }
      @media screen and (min-width: 550) {
        width: 480px;
        height: 600px;
      }
      width: 380px;
    height: 500px;
    border-radius: 10px;
    background: white;
    overflow: hidden;
    // box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    //   0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    //   0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
    display: flex;
    flex-direction: column;

    nav {
        background: #fdfdfd;
        padding: 5px 5px 0;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 1px solid #eeeeee;
        height: 44px;
      }
      
      .tabs {
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-wrap: nowrap;
        width: 100%;
      }
      
      main {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 128px;
        flex-grow: 1;
        user-select: none;
        width: 100%;
      }
      
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 20px;
      }
      
      ul {
        display: flex;
        width: 100%;
      }
      
      li {
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
        padding: 10px 15px;
        position: relative;
        background: white;
        cursor: pointer;
        // height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        min-width: 0;
        position: relative;
        user-select: none;
      }
      
      .underline {
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background: var(--accent);
      }
      
      li.selected {
        background: #eee;
      }
      
      li button {
        width: 20px;
        height: 20px;
        border: 0;
        background: #fff;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        stroke: #000;
        margin-left: 10px;
        cursor: pointer;
        flex-shrink: 0;
      }
      
      .background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 300px;
        background: #fff;
      }
      
      .add-item {
        width: 30px;
        height: 30px;
        background: #eee;
        border-radius: 50%;
        border: 0;
        cursor: pointer;
        align-self: center;
      }
      
      .add-item:disabled {
        opacity: 0.4;
        cursor: default;
        pointer-events: none;
      }
      
  }
  
 